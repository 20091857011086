$(function () {
  // news
  $.get("https://www.elfinanciero.com.mx/economia", function( data ) {
    var $news = $(data).find(".most-read > ol")
    $news.find('img').each(function(index, el) {
      var src = $(el).attr('src')
      $(el).attr('src', "https://www.elfinanciero.com.mx" + src)
      $(el).addClass('img-fluid')
    })

    $news.find('a').each(function(index, el) {
      var href = $(el).attr('href')
      $(el).attr('href', "https://www.elfinanciero.com.mx" + href)
      $(el).attr('target', '_blank')
    })

    $news.find('li').addClass("item")

    $('.MultiCarousel-inner').append($news.html())

    ResCarouselSize();
  });

  // charts
  $.get("https://www.elfinanciero.com.mx", function( data ) {
    var $charts = $(data).find('.is-markets')

    $charts.find('.columns').addClass('row')
    $charts.find('.column').addClass('col-lg-2 col-md-4 col-6')

    $charts.find('a').each(function(index, el) {
      var href = $(el).attr('href')
      $(el).attr('href', "https://www.elfinanciero.com.mx" + href)
      $(el).attr('target', '_blank')
    })

    // $charts.find('.container').removeClass('container').addClass('container-fluid')

    $('.financiero-charts').append($charts.html())
  })


  // carousel
  var itemsMainDiv = ('.MultiCarousel');
  var itemsDiv = ('.MultiCarousel-inner');
  var itemWidth = "";

  $('.leftLst, .rightLst').click(function () {
    var condition = $(this).hasClass("leftLst");
    if (condition)
      click(0, this);
    else
      click(1, this)
  });

  $(window).resize(function () {
    ResCarouselSize();
  });

  //this function define the size of the items
  function ResCarouselSize() {
    var incno = 0;
    var dataItems = ("data-items");
    var itemClass = ('.item');
    var id = 0;
    var btnParentSb = '';
    var itemsSplit = '';
    var sampwidth = $(itemsMainDiv).width();
    var bodyWidth = $('body').width();
    $(itemsDiv).each(function () {
      id = id + 1;
      var itemNumbers = $(this).find(itemClass).length;
      btnParentSb = $(this).parent().attr(dataItems);
      itemsSplit = btnParentSb.split(',');
      $(this).parent().attr("id", "MultiCarousel" + id);

      if (bodyWidth >= 1200) {
        incno = itemsSplit[3];
        itemWidth = sampwidth / incno;
      }
      else if (bodyWidth >= 992) {
        incno = itemsSplit[2];
        itemWidth = sampwidth / incno;
      }
      else if (bodyWidth >= 768) {
        incno = itemsSplit[1];
        itemWidth = sampwidth / incno;
      }
      else {
        incno = itemsSplit[0];
        itemWidth = sampwidth / incno;
      }
      $(this).css({ 'transform': 'translateX(0px)', 'width': itemWidth * itemNumbers });
      $(this).find(itemClass).each(function () {
          $(this).outerWidth(itemWidth);
      });

      $(".leftLst").addClass("over");
      $(".rightLst").removeClass("over");
    });
  }

  //this function used to move the items
  function ResCarousel(e, el, s) {
    var leftBtn = ('.leftLst');
    var rightBtn = ('.rightLst');
    var translateXval = '';
    var divStyle = $(el + ' ' + itemsDiv).css('transform');
    var values = divStyle.match(/-?[\d\.]+/g);
    var xds = Math.abs(values[4]);
    if (e == 0) {
      translateXval = parseInt(xds) - parseInt(itemWidth * s);
      $(el + ' ' + rightBtn).removeClass("over");

      if (translateXval <= itemWidth / 2) {
        translateXval = 0;
        $(el + ' ' + leftBtn).addClass("over");
      }
    }
    else if (e == 1) {
      var itemsCondition = $(el).find(itemsDiv).width() - $(el).width();
      translateXval = parseInt(xds) + parseInt(itemWidth * s);
      $(el + ' ' + leftBtn).removeClass("over");

      if (translateXval >= itemsCondition - itemWidth / 2) {
        translateXval = itemsCondition;
        $(el + ' ' + rightBtn).addClass("over");
      }
    }
    $(el + ' ' + itemsDiv).css('transform', 'translateX(' + -translateXval + 'px)');
  }

  function click(ell, ee) {
    var Parent = "#" + $(ee).parent().attr("id");
    var slide = $(Parent).attr("data-slide");
    ResCarousel(ell, Parent, slide);
  }
})
